export namespace Plans {
  export interface IMembershipPlan {
    id: number;
    description: string;
    details: string;
    value: number;
    intervalMonths: number;
    imageUrl: string;
    imagePublicId: string;
    chargeMembershipFee: boolean;
    membershipFeeValue: number;
    activationType: number;
    affiliateCommissionValue: number;

    creationDate: string;
    creationUserId: number;
    updateDate: string;
    updateUserId: number;
    deletionDate: string;
    deletionUserId: string;
  }

  export class MembershipPlan implements IMembershipPlan {
    id: number = 0;
    description: string = "";
    details: string = "";
    value: number = 0;
    intervalMonths: number = 0;
    imageUrl: string = "";
    imagePublicId: string = "";
    chargeMembershipFee: boolean = false;
    membershipFeeValue: number = 0;
    activationType: number = 0;
    affiliateCommissionValue: number = 0;

    creationDate: string = "";
    creationUserId: number = 0;
    updateDate: string = "";
    updateUserId: number = 0;
    deletionDate: string = "";
    deletionUserId: string = "";
  }

  export interface IMembershipPlanRequest {
    id: number;
    description: string;
    details: string;
    value: number;
    intervalMonths: number;
    imageUrl: string;
    imagePublicId: string;
  }

  export class MembershipPlanRequest implements IMembershipPlanRequest {
    id: number = 0;
    description: string = "";
    details: string = "";
    value: number = 0;
    intervalMonths: number = 0;
    imageUrl: string = "";
    imagePublicId: string = "";
  }
}
