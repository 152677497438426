<template>
  <v-app id="app" style="background-color: #fff">
    <div>
      <NavigationMenu :menuStatus="menuStatus" />
    </div>
    <DrawerMenu :menuStatus="menuStatus" />
    <v-main class="main-app-screen">
      <router-view :key="$route.fullPath" />
    </v-main>
    <div app>
      <FooterMenu />
      <v-snackbar timeout="10000" v-model="$store.state.app.snackbar.value">
        <div v-if="$store.state.app.snackbar.type == 1" class="d-flex">
          <div class="mr-2 pt-2">
            <div
              style="
                background-color: #d5fbd8;
                border-radius: 50%;
                width: 32px;
                height: 32px;
              "
              class="pt-1 pl-1"
            >
              <v-icon color="green">check_circle</v-icon>
            </div>
          </div>
          <div class="d-block">
            <div>
              <strong style="font-family: 'Montserrat', sans-serif"
                >Sucesso!</strong
              >
            </div>
            <div>
              <span style="font-family: 'Montserrat', sans-serif">{{
                $store.state.app.snackbar.text
              }}</span>
            </div>
          </div>
          <div class="ml-4">
            <v-btn text @click="closeSnackbar" color="primary">
              <strong>OK</strong></v-btn
            >
          </div>
        </div>
        <div v-if="$store.state.app.snackbar.type == 2" class="d-flex">
          <div class="mr-2 pt-1">
            <div
              style="
                background-color: #ffe8e8;
                border-radius: 50%;
                width: 32px;
                height: 32px;
              "
              class="pt-1 pl-1"
            >
              <v-icon color="red" outlined>cancel</v-icon>
            </div>
          </div>
          <div class="d-block">
            <div>
              <strong style="font-family: 'Montserrat', sans-serif"
                >Ops, algo deu errado!</strong
              >
            </div>
            <div>
              <span style="font-family: 'Montserrat', sans-serif">{{
                $store.state.app.snackbar.text
              }}</span>
            </div>
          </div>
          <div class="ml-4">
            <v-btn text @click="closeSnackbar" color="primary">
              <strong>OK</strong></v-btn
            >
          </div>
        </div>
        <div v-if="$store.state.app.snackbar.type == 3" class="d-flex">
          <div class="d-block">
            <div>
              <strong style="font-family: 'Montserrat', sans-serif"
                >Aviso de cookies</strong
              >
            </div>
            <div>
              <span style="font-family: 'Montserrat', sans-serif">{{
                $store.state.app.snackbar.text
              }}</span>
            </div>
          </div>
          <div class="ml-4">
            <v-btn text @click="closeSnackbar" color="primary">
              <strong>OK</strong></v-btn
            >
          </div>
        </div>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { ROUTERS } from "./router/router";
import {
  APP_SET_LOGIN,
  APP_SET_MENU,
  HIDE_SNACK,
} from "./store/MUTATIONS-TYPES";

@Component({
  components: {
    NavigationMenu: () => import("@/views/marketPet/menus/navigationMenu"),
    AppSearch: () => import("@/views/marketPet/components/appSearch/appSearch"),
    FooterMenu: () => import("@/views/marketPet/menus/footer/footerMenu"),
    NotificationsComponent: () =>
      import(
        "@/views/marketPet/components/notifications/notificationsComponent"
      ),
    DrawerMenu: () => import("@/views/marketPet/menus/drawerMenu"),
  },
})
export default class App extends Vue {
  setLogin!: any;
  setMenu!: any;
  mobileView: boolean = false;
  showNav: boolean = false;

  created() {
    this.getStatus();
  }

  getStatus() {
    this.setLogin = localStorage.getItem(APP_SET_LOGIN);
  }

  get menuStatus() {
    let status = localStorage.getItem(APP_SET_MENU);

    return status;
  }

  openLogin() {
    this.fillOpt("false");
    this.$router.push(ROUTERS.ACCESS_CONTROL.LOGIN.PATH);
  }

  fillOpt(value: string) {
    localStorage.setItem(APP_SET_LOGIN, "true");
    this.setLogin = localStorage.getItem(APP_SET_LOGIN);
  }

  closeSnackbar() {
    const { commit } = this.$store;
    commit(HIDE_SNACK);
  }

  getGeolocation() {
    if ("geolocation" in navigator) {
      const watcher = navigator.geolocation.watchPosition(
        function (position) {},
        function (error) {
          console.log(error);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 30000,
        }
      );
    } else {
      alert("Encontramos um problema com sua localização.");
    }
  }
}
</script>
