import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import {
  APP_SET_NAVBAR,
  APP_SET_MENU,
  SESSION_SEVERAL_DATA,
  SESSION_USER_DATA,
  SESSION_USER_ADDRESS,
  SESSION_BUSINESS_UNIT,
  SESSION_NAVIGATION_TYPE,
  SESSION_PERSON_TYPE,
  SHOW_SNACK,
  HIDE_SNACK,
  SHOW_SNACK_ERROR,
  SESSION_USER_AFFILIATE,
} from "./MUTATIONS-TYPES";
import { BusinessUnits } from "@/interfaces/IBusinessUnits";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: {
      snackbar: {
        value: false,
        text: "",
        type: 1,
      },
    },
    sessionSeveralData: SESSION_SEVERAL_DATA,
    sessionUser: SESSION_USER_DATA,
    sessionUserAddress: SESSION_USER_ADDRESS,
    sessionUserAffiliate: SESSION_USER_AFFILIATE,
    sessionBusinessUnit: SESSION_BUSINESS_UNIT,
    sessionNavigationType: SESSION_NAVIGATION_TYPE,
    sessionPersonType: SESSION_PERSON_TYPE,
    menuDrawer: false,
    mobileDevice: false,
    selectedBusinessUnit: new BusinessUnits.BusinessUnit(),
    tabSelected: -1,
    selectedBusisnessUnitId: 0,
    selectedProfile: 0,
    distanceSearch: 10,
    _2FA: false,
    countCookies: 0,
    petData: Object,
    postContent: '',
    selectedBusinessUnitList: [],
    petsList: [],
    FollowingAndFollowersList: []
  },

  plugins: [
    new VuexPersistence({
      storage: window.localStorage,
    }).plugin,
  ],

  mutations: {
    [SHOW_SNACK](state, message) {
      state.app.snackbar.value = true;
      state.app.snackbar.text = message;
      state.app.snackbar.type = 1;
    },
    [SHOW_SNACK_ERROR](state, message) {
      state.app.snackbar.value = true;
      state.app.snackbar.text = message;
      state.app.snackbar.type = 2;
    },
    [HIDE_SNACK](state) {
      state.app.snackbar.value = false;
    },
    sessionSeveralData(state, data) {
      state.sessionSeveralData = data;
    },
    userSessionInfos(state, data) {
      state.sessionUser = data;
    },
    sessionUserAddress(state, data) {
      state.sessionUserAddress = data;
    },
    sessionUserAffiliate(state, data) {
      state.sessionUserAffiliate = data;
    },
    sessionDistanceSearch(state, data) {
      state.distanceSearch = data;
    },
    session_2FA(state, data) {
      state._2FA = data;
    },
    sessionBusinessUnit(state, data) {
      state.sessionBusinessUnit = data;
    },
    sessionNavigationUserType(state, data) {
      state.sessionNavigationType = data;
    },
    sessionPersonType(state, data) {
      state.sessionPersonType = data;
    },
    sessionMobileDevice(state, data) {
      state.mobileDevice = data;
    },
    sessionSelectedBusisnessUnitId(state, data) {
      state.selectedBusisnessUnitId = data;
    },
    sessionBusinessUnitProfileTabSelected(state, data) {
      state.tabSelected = data;
    },
    sessionSelectedProfile(state, data) {
      state.selectedProfile = data;
    },
    sessionPetSelected(state, data) {
      state.petData = data;
    },
    sessionFollowingAndFollowers(state, data) {
      state.FollowingAndFollowersList = data;
    },
    

    logout(state) {
      //State reset
      (state.sessionSeveralData = SESSION_SEVERAL_DATA),
      (state.sessionUser = SESSION_USER_DATA),
      (state.sessionUserAddress = SESSION_USER_ADDRESS),
      (state.sessionUserAffiliate = SESSION_USER_AFFILIATE),
      (state.sessionBusinessUnit = SESSION_BUSINESS_UNIT),
      (state.sessionNavigationType = SESSION_NAVIGATION_TYPE),
      (state.sessionPersonType = SESSION_PERSON_TYPE);
      (state.selectedBusinessUnit = new BusinessUnits.BusinessUnit()),
      (state.tabSelected = 0),
      (state.selectedBusisnessUnitId = 0),
      (state.selectedProfile = 0),
      (state.distanceSearch = 10),
      (state._2FA = false);
      (state.FollowingAndFollowersList = [])

      //LocalStorage reset
      localStorage.clear();
      localStorage.setItem(APP_SET_NAVBAR, "true");
      localStorage.setItem(APP_SET_MENU, "true");
    },
  },

  actions: {
    setSessionSeveralData({ commit }, data) {
      commit("sessionSeveralData", data);
    },
    setUserSessionInfos({ commit }, data) {
      commit("userSessionInfos", data);
    },
    setSessionUserAddress({ commit }, data) {
      commit("sessionUserAddress", data);
    },
    setSessionUserAffiliate({ commit }, data) {
      commit("sessionUserAffiliate", data);
    },
    setDistanceSearch({ commit }, data) {
      commit("sessionDistanceSearch", data);
    },
    set_2FA({ commit }, data) {
      commit("session_2FA", data);
    },
    setSessionBusinessUnit({ commit }, data) {
      commit("sessionBusinessUnit", data);
    },
    setSessionNavigationUserType({ commit }, data) {
      commit("sessionNavigationUserType", data);
    },
    setSessionPersonType({ commit }, data) {
      commit("sessionPersonType", data);
    },
    setIsMobileDevice({ commit }, data) {
      commit("sessionMobileDevice", data);
    },
    setSelectedBusisnessUnitId({ commit }, data) {
      commit("sessionSelectedBusisnessUnitId", data);
    },
    setsessionBusinessUnitProfileTabSelected({ commit }, data) {
      commit("sessionBusinessUnitProfileTabSelected", data);
    },
    setSessionSelectedProfile({ commit }, data) {
      commit("sessionSelectedProfile", data);
    },
    setSessionPetSelected({ commit }, data) {
      commit("sessionPetSelected", data);
    },
    setSessionFollowingAndFollowersList({ commit }, data) {
      commit("sessionFollowingAndFollowers", data);
    },
    

    logout({ commit }) {
      commit("logout");
    },
  },

  getters: {
    currentSessionSeveralData: ({ sessionSeveralData }) => {
      let result = JSON.parse(window.localStorage.vuex);
      if (result.sessionSeveralData != null) {
        return result.sessionSeveralData;
      } else {
        return null;
      }
    },
    currentSessionUser: ({ sessionUser }) => {
      // Quando o usuário acessa a página pela primeira vez, não existe localStorage
      // Essa abordagem cria muitos erros circulares e impede o carregamento do router
      let result = JSON.parse(window.localStorage.vuex || "{}");
      return result.sessionUser;
    },
    currentSessionUserAddress: ({ sessionUserAddress }) => {
      let result;
      try {
        result = JSON.parse(window.localStorage.vuex);
        return result.sessionUserAddress;
      } catch (error) {
        console.log("Erro ao parsear o Json", error);
        return null;
      }
    },

    currentSessionUserAffiliate: ({ sessionUserAffiliate }) => {
      let result;
      try {
        result = JSON.parse(window.localStorage.vuex);
        return result.sessionUserAffiliate;
      } catch (error) {
        console.log("Erro ao parsear o Json", error);
        return null;
      }
    },
    currentDistanceSearch: ({ distanceSearch }) => {
      let result;
      try {
        result = JSON.parse(window.localStorage.vuex);
        return result.distanceSearch;
      } catch (error) {
        console.log("Erro ao parsear o Json", error);
        return null;
      }
    },
    currentSessionBusinessUnit: ({ sessionBusinessUnit }) => {
      let result = JSON.parse(window.localStorage.vuex);
      if (result.sessionBusinessUnit != null) {
        return result.sessionBusinessUnit;
      } else {
        return null;
      }
    },
    currentSessionNavigationUserType: ({ sessionNavigationType }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.sessionNavigationType;
    },
    currentSessionPersonType: ({ sessionPersonType }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.sessionPersonType;
    },
    currentSelectedBusisnessUnitId: ({ selectedBusisnessUnitId }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.selectedBusisnessUnitId;
    },
    currentBusinessUnitProfileTabSelected: ({ tabSelected }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.tabSelected;
    },
    currentSessionSelectedProfile: ({ selectedProfile }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.selectedProfile;
    },
    currentSessionPetSelected: ({ petData }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.petData;
    },
    currentIsMobileDevice: ({ mobileDevice }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.mobileDevice;
    },
    current2FA: ({ _2FA }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result._2FA;
    },

    currentSessionFollowingAndFollowers: ({ petData }) => {
      let result = JSON.parse(window.localStorage.vuex);
      return result.FollowingAndFollowersList;
    },
  },
});
